<template>
  <section class="container">
    <stockTopMenu curPosition="业绩日历" windowId="performanceCalendar" module="yejicalendar"></stockTopMenu>
    <main class="main-content row">
      <aside class="aside-content">
        <div class="date-range row align-center space-between">
          <el-date-picker
              v-model="dateRange"
              type="daterange"
              size="small"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyyMMdd"
              :picker-options="pickerOptions"
              :clearable="false">
          </el-date-picker>
          <el-button class="sub-btn" type="primary" size="small" :loading="searchLoading" @click="fetchUserSells">查 询</el-button>
        </div>

        <div class="perform-dept">
          <p class="row align-center space-between title-row" @click="deptSales = !deptSales">
            <span>门店业绩</span>
            <i class="el-icon-arrow-down"></i>
          </p>
          <div class="row align-center flex-wrap sales-card" v-show="deptSales">
            <div class="sales-item">
              <p>总业绩</p>
              <span>￥{{deptSellAmount || '0.00'}}</span>
            </div>
            <template v-for="pay in deptSellPaymentJsonList">
              <div class="sales-item" :key="pay.id">
                <p>{{pay.name}}</p>
                <span>￥{{pay.totalAmount || '0.00'}}</span>
              </div>
            </template>
            <div class="sales-item">
              <p>客单价</p>
              <span>￥{{deptCustomerUnitPrice || '0.00'}}</span>
            </div>
            <div class="sales-item">
              <p>件单价</p>
              <span>￥{{deptUnitPrice || '0.00'}}</span>
            </div>
            <div class="sales-item">
              <p>连带率</p>
              <span>{{deptJoinRate || '0.00'}}</span>
            </div>
            <div class="sales-item">
              <p>挑战目标</p>
              <span>￥{{targetAmount || '0.00'}}</span>
            </div>
          </div>
        </div>

        <div class="perform-user">
          <p class="row align-center space-between title-row" @click="userSales = !userSales">
            <span>导购业绩</span>
            <i class="el-icon-arrow-down"></i>
          </p>
          <div class="user-card" v-show="userSales">
            <div class="user-item" v-for="item in userSellInfoList" :key="item.userId">
              <div class="row align-center space-between user-total">
                <p class="row align-center text-center">
                  <span v-if="!item.avatar" class="avator-text">{{item.userName.substring(0, 1)}}</span>
                  <img v-else class="avator-img" :src="item.avatar" alt="avator">
                  <span class="avator-name">{{item.userName}}</span>
                  <span class="avator-name">￥{{item.userSellAmount || '0.00'}}</span>
                </p>
                <div><span class="month-perform" @click="currentItem = item;fetchMonth('user')">查看本月业绩</span></div>
              </div>
              <div class="row sales-info">
                <div>
                  <p>件单价</p>
                  <span>￥{{item.unitPrice || '0.00'}}</span>
                </div>
                <div>
                  <p>客单价</p>
                  <span>￥{{item.customerUnitPrice || '0.00'}}</span>
                </div>
                <div>
                  <p>连带率</p>
                  <span>{{item.joinRate || '0.00'}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </aside>
      <section class="calendar-content" v-loading="calendarLoading" element-loading-text="请稍后，全力加载中..." element-loading-spinner="el-icon-loading">
        <section class="calendar-head row align-center space-between">
          <div class="row align-center">
            <span>选择月份：&nbsp;</span>
            <el-date-picker
                v-model="month"
                type="month"
                size="small"
                placeholder="请选择月份"
                format="yyyy-MM"
                value-format="yyyyMM"
                :picker-options="monthOption"
                :clearable="false"
                @change="fetchMonth('month')">
            </el-date-picker>
          </div>
          <div class="perform-title">{{titleName}} ~ {{month.substring(0, 4) + '年' + month.substring(4, 6) + '月'}}业绩</div>
          <div class="dept-perform">
              <span @click="teamVisible = true">查看公单业绩</span>
              <span @click="currentItem = null;fetchMonth('deptId')">查看店铺业绩</span>
          </div>
        </section>

        <section class="calendar-table">
          <div class="row align-center space-center text-center cal-tab-head">
            <div>星期一</div>
            <div>星期二</div>
            <div>星期三</div>
            <div>星期四</div>
            <div>星期五</div>
            <div>星期六</div>
            <div>星期天</div>
          </div>
          <div class="row flex-wrap cal-tab-body">
            <div
                v-for="(item, index) in performList"
                :key="index"
                :class="['cal-tab-cell', cellDay === item.forDate && !item.isNotCurrentMonth ? 'select-day' : '', item.isNotCurrentMonth ? 'other-month' : '']"
                @click="calendarOnClick(item)">
              <p class="row align-center">
                <span class="date-day">{{item.forDate.substring(8, 10)}}</span>
                <span
                    v-if="!item.isNotCurrentMonth"
                    class="day-salary"
                    :style="{background:
                    Number(item.sellAmount) < Number(item.basicTarget) ? '#d7d7d7' :
                    Number(item.sellAmount) >= Number(item.basicTarget) && Number(item.sellAmount) < Number(item.challengeTarget) ? '#04be75' :
                    Number(item.sellAmount) >= Number(item.challengeTarget) ? '#f76c4e' : '#d7d7d7'}">
                    业绩：￥{{item.sellAmount || '0.00'}}
                </span>
              </p>
              <div class="row flex-wrap space-between pay-type" style="column-gap: 5px;" v-if="!item.isNotCurrentMonth">
                <template v-for="(jtem, jdx) in item.paymentJsonList">
                <p
                    style="height: 24px;line-height: 24px;margin-top: 10px;font-size: 12px;flex: 1;min-width: 47%;font-weight: 800;text-align: center;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;"
                    :style="{background:jtem.colorValue}"
                    :title="jtem.name.substr(0,1) + ': ￥' + jtem.totalAmount"
                    :key="jdx">
                    {{jtem.name.substr(0,1)+": ￥"+jtem.totalAmount}}
                </p>
                </template>
              </div>
              <p v-if="!item.isNotCurrentMonth" class="challeng-target">挑战目标：￥{{item.challengeTarget || '0.00'}}</p>
            </div>
          </div>
        </section>
      </section>
    </main>
    <TeamSales v-if="teamVisible" @close="teamVisible = false"></TeamSales>
  </section>
</template>

<script>
  import moment from "moment";
  import stockTopMenu from '@/components/stockTopMenu.vue'
  import TeamSales from './components/teamSales'
  import {queryUserSellStatis, queryMonthSellStatis} from "@/libs/http/api";
  export default {
    name: 'performanceCalendar',
    components: {
      stockTopMenu,
      TeamSales
    },
    data(){
      return{
        searchLoading: false,
        calendarLoading: false,
        pickerOptions: {
          disabledDate(time) {
            let lastMonth = moment().subtract(1, 'months').format('yyyy-MM')
            let lastDateString = new Date(lastMonth + '-01 00:00:00').getTime()
            return time.getTime() > Date.now() || time.getTime() < lastDateString
          }
        },
        monthOption: {
          disabledDate(time) {
            let lastMonth = moment().subtract(1, 'months').format('yyyy-MM')
            let lastDateString = new Date(lastMonth).getTime()
            return time.getTime() > Date.now() || time.getTime() < lastDateString
          }
        },
        dateRange: [moment().format('yyyyMMDD'), moment().format('yyyyMMDD')],
        today: moment().format('yyyy-MM-DD'),
        month: moment().format('yyyyMM'),
        deptName: '',
        deptSellAmount: '',
        deptCustomerUnitPrice: '',
        deptUnitPrice: '',
        deptJoinRate: '',
        targetAmount: '',
        userSellInfoList: [],
        performList: [],
        cellDay: moment().format('yyyy-MM-DD'),
        deptId: '',
        titleName: '',
        currentItem: null,
        deptSellPaymentJsonList: [],
        deptSales: true,
        userSales: true,
        teamVisible: false
      }
    },
    mounted(){
      this.fetchUserSells();
      this.fetchMonth('deptId');
    },
    methods: {
      fetchUserSells(){
        this.searchLoading = true
        queryUserSellStatis({
          startTime: this.dateRange[0],
          endTime: this.dateRange[1]
        }).then(data=>{
          this.deptSellAmount = data.deptSellAmount
          this.deptCustomerUnitPrice = data.deptCustomerUnitPrice
          this.deptUnitPrice = data.deptUnitPrice
          this.deptJoinRate = data.deptJoinRate
          this.targetAmount = data.deptTargetAmount
          this.userSellInfoList = data.userSellInfoList ||  []
          this.deptSellPaymentJsonList = data.deptSellPaymentJsonList || []
          this.searchLoading = false
        }).catch(error=>{
          this.$message({type: "error",message: error,duration: 2000,showClose: true})
          this.searchLoading = false
        })
      },
      fetchMonth(type){
        let reqData = {
          month: this.month,
          deptId: this.$cache.local.get('deptId')
        }
        if(type === 'user' || (type === 'month' && this.currentItem)){
          reqData['userId'] = this.currentItem.userId
          this.titleName = this.currentItem.userName
        }else{
          this.titleName = this.$cache.local.get('deptCode')
        }
        this.calendarLoading = true
        queryMonthSellStatis(reqData).then(data=>{
          this.performList = []
          let monthData = data || []
          //处理上个月的日期
          let preMonthDay = moment(this.month).subtract(1, 'months').endOf('month').format('YYYYMMDD')  //当前月份上月的最后一天
          let preList = [];
          for(let i = 0; i < moment(this.month + '01').format('E') - 1; i++){
            preList.unshift({
              forDate: moment(preMonthDay - i + '').format('yyyy-MM-DD'),
              isNotCurrentMonth: true
            })
          }
          this.performList = preList.concat(monthData)
          //处理下个月的日期
          let netMonthDay = moment(this.month).startOf('month').subtract(-1,'month').format('YYYYMMDD')  //当前月份下月第一天
          let netList = []
          for(let i = 0; i < 42 - this.performList.length; i++){
            netList.push({
              forDate: moment(Number(netMonthDay) + i + '').format('yyyy-MM-DD'),
              isNotCurrentMonth: true
            })
          }
          if(netList.length){
            this.performList = this.performList.concat(netList)
          }
          this.calendarLoading = false
        }).catch(error=>{
          this.calendarLoading = false
          this.$message({type: "error",message: error,duration: 2000,showClose: true})
        })
      },
      calendarOnClick(data){
        if(data.isNotCurrentMonth) return
        this.cellDay = data.forDate;
        this.dateRange = [moment(data.forDate).format('yyyyMMDD'), moment(data.forDate).format('yyyyMMDD')];
        this.fetchUserSells();
      }
    }
  }
</script>

<style lang="scss" scoped>
.main-content{
  background: #fff;
  height: 92vh;
  margin: 10px;
  .aside-content{
    border-right: 1px solid #ddd;
    width: 420px;
    padding: 20px 10px;
    .date-range{
      .sub-btn{
        margin-left: 10px;
      }
    }
    .perform-dept{
      .sales-card{
        border: 1px solid #ddd;
        border-radius: 4px;
        background: rgb(244,246,250);
        width: 100%;
        .sales-item{
          width: calc(100% / 3);
          text-align: center;
          padding: 10px 0;
          p{
            font-size: 14px;
            color: #666;
            margin-bottom: 5px;
          }
          span{
            font-size: 18px;
            color: #02A7F0;
            font-family: 'font-Medium';
          }
        }
      }
    }
    .title-row{
      margin: 20px 0;
      cursor: pointer;
    }
    .perform-user{
      .user-card{
        max-height: 55vh;
        overflow: auto;
        .user-item{
          border: 1px solid #ddd;
          background: rgb(244,246,250);
          border-radius: 4px;
          margin-bottom: 15px;
          padding: 10px;
          .user-total{
            .avator-text {
              width: 26px;
              height: 26px;
              line-height: 26px;
              border-radius: 50%;
              background: rgb(2,167,240);
              color: #fff;
              font-size: 16px;
            }
            .avator-img{
              width: 26px;
              height: 26px;
              float:left;
              border-radius: 50%;
            }
            .avator-name{
              margin-top: -3px;
              margin-left: 6px;
              white-space: nowrap;
            }
            .month-perform {
              color: #fff;
              background: rgb(2, 167, 240);
              font-size: 14px;
              padding: 4px 10px;
              border-radius: 2px;
              cursor: pointer;
            }
          }
          .sales-info{
            width: 100%;
            margin-top: 10px;
            div{
              width: calc(100% / 3);
              text-align: center;
              p{
                color: #999;
                font-size: 14px;
                margin-bottom: 5px;
              }
              span{
                font-size: 16px;
                color: #02A7F0;
                font-family: 'font-Medium';
              }
            }
          }
        }
      }
    }
  }
  .calendar-content{
    padding: 15px;
    width: 100%;
    .calendar-head{
      font-family: 'font-Regular';
      margin-bottom: 15px;
      .current-month{
        font-size: 24px;
        margin-right: 20px;
      }
      .arrow{
        width: 30px;
        background: rgb(249,250,252);
        border:1px solid #ddd;
        height: 35px;
        margin-right: 20px;
        cursor:pointer;
        i{
          font-size: 20px;
          font-weight: bolder;
        }
      }
      .perform-title{
        font-size: 18px;
        margin-right: 300px;
      }
      .dept-perform{
        span{
            color: rgb(2,167,240);
            border: 1px solid rgb(2,167,240);
            font-size: 14px;
            padding: 5px 8px;
            cursor:pointer;
            border-radius: 2px;
            margin-left: 20px;
        }
      }
    }
    .calendar-table{
      .cal-tab-head{
        border:1px solid rgb(230,234,240);
        border-left: none;
        div{
          width: calc(100% / 7);
          border-left: 1px solid rgb(230,234,240);
          line-height: 40px;
        }
      }
      .cal-tab-body{
        font-size: 14px;
        border-left: 1px solid rgb(230,234,240);
        .cal-tab-cell{
          font-size: 14px;
          height: 140px;
          padding: 6px 8px ;
          border: 1px solid rgb(230,234,240);
          border-top:none;
          border-left:none;
          width: calc(100% / 7);
          cursor: pointer;
          font-family: 'font-Medium';
        }
        .challeng-target{
          background: #fee1dd;
          height: 24px;
          line-height: 24px;
          text-align: center;
          margin-top: 10px;
        }
      }
    }
    .date-day{
      font-size: 18px;
    }
    .day-salary{
      color: #fff;
      height: 24px;
      line-height: 24px;
      margin-left: 6px;
      width: 100%;
      text-align: center;
    }
    .select-day{
      background: #02A7F0 !important;
      .date-day{
        color: #fff;
      }
    }
    .other-month{
      color: #ddd;
    }
  }
}
</style>