<template>
  <el-dialog
    append-to-body
    width="1200px"
    top="10vh"
    custom-class="team-sales"
    v-loading="fetchLoading"
    element-loading-text="请稍后，全力加载中..."
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0)"
    :show-close="false"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    @close="close">
    <div slot="title" class="row align-center space-between team-title">
      <div class="row align-center">
        <i></i>
        <span>公单业绩</span>
      </div>
      <i class="el-icon-close" @click="close"></i>
    </div>
    <section class="team-body">
      <el-form size="small" class="team-from" :inline="true" :model="formField">
        <el-form-item label="姓名">
          <el-input clearable style="width: 120px" v-model="formField.name" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="单号">
          <el-input clearable v-model="formField.innerSn" placeholder="请输入单号"></el-input>
        </el-form-item>
        <el-form-item label="日期">
          <el-date-picker
            clearable
            v-model="formField.date"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button @click="resetQuery">重 置</el-button>
          <el-button type="primary" @click="searchHandel">查 询</el-button>
        </el-form-item>
      </el-form>
      <div class="team-table">
        <el-table
          border
          show-summary
          ref="table"
          height="65vh"
          :data="tableData"
          :header-cell-style="{
            padding: '5px',
            color: '#333',
            background: '#f0f0f0'
          }"
          :cell-style="{
            color: '#333',
            padding: '5px'
          }"
          :summary-method="getSummaries"
          :span-method="objectSpanMethod">
          <el-table-column prop="forDate" label="日期"> </el-table-column>
          <el-table-column prop="ruleName" label="公单规则名称"> </el-table-column>
          <el-table-column prop="innerSn" label="单号"> </el-table-column>
          <el-table-column prop="orderPrice" label="公单总业绩"> </el-table-column>
          <el-table-column prop="realName" label="姓名"> </el-table-column>
          <el-table-column prop="postName" label="分配时职级"> </el-table-column>
          <el-table-column prop="proportion" label="分配时份额"> </el-table-column>
          <el-table-column prop="userAmount" label="个人分配业绩"> </el-table-column>
        </el-table>
        <el-pagination
            background
            style="margin-top: 10px;"
            layout="total, sizes, prev, pager, next"
            :current-page="currentPage"
            :page-sizes="[20, 50, 100, 200, 500]"
            :pageSize="pageSize"
            :total="totalItems"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange">
        </el-pagination>
      </div>
    </section>
  </el-dialog>
</template>

<script>
import { queryPublicSellList } from '@/libs/http/modules/posOrder'
export default {
  name: 'TeamSales',
  props: {},
  data() {
    return {
      dialogVisible: true,
      formField: {
        name: '',
        date: '',
        innerSn: ''
      },
      tableData: [],
      fetchLoading: false,
      spanArr: [],
      position: 0,
      currentPage: 1,
      pageSize: 50,
      totalItems: 0
    }
  },
  methods: {
    close() {
      this.dialogVisible = false
      this.$emit('close')
    },
    resetQuery() {
      for (let key in this.formField) {
        this.formField[key] = ''
      }
      this.currentPage = 1
      this.fetchData()
    },
    searchHandel(){
      this.currentPage = 1
      this.fetchData()
    },
    handleSizeChange(e){
      this.pageSize = e
      this.fetchData()
    },
    handleCurrentChange(e){
      this.currentPage = e
      this.fetchData()
    },
    fetchData() {
      this.fetchLoading = true
      let params = {
        page: this.currentPage,
        pageSize: this.pageSize,
        marketIds: [this.$cache.local.get('marketingUnitId')]
      }
      if (this.formField.name) {
        params['userName'] = this.formField.name
      }
      if (this.formField.innerSn) {
        params['innerSn'] = this.formField.innerSn
      }
      if (this.formField.date && this.formField.date.length) {
        params['startTime'] = this.formField.date[0]
        params['endTime'] = this.formField.date[1]
      }
      queryPublicSellList(params)
        .then(data => {
          this.tableData = data.items || []
          this.totalItems = Number(data.totalItem) || 0
          this.initData(this.tableData)
          this.fetchLoading = false
        })
        .catch(error => {
          this.$message({ type: 'error', message: error, duration: 2000, showClose: true })
          this.fetchLoading = false
        })
    },
    objectSpanMethod({ rowIndex, columnIndex }) {
      if ([0, 1, 2, 3].includes(columnIndex)) {
        const _row = this.spanArr[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
    },
    initData(list) {
      this.spanArr = []
      this.position = 0
      list.forEach((item, index) => {
        if (index === 0) {
          this.spanArr.push(1)
          this.position = 0
        } else {
          if (
            list[index].forDate === list[index - 1].forDate &&
            list[index].code === list[index - 1].code &&
            list[index].ruleName === list[index - 1].ruleName &&
            list[index].orderPrice === list[index - 1].orderPrice &&
            list[index].innerSn === list[index - 1].innerSn
          ) {
            this.spanArr[this.position] += 1
            this.spanArr.push(0)
          } else {
            this.spanArr.push(1)
            this.position = index
          }
        }
      })
    },
    getSummaries(params) {
      const { columns, data } = params
      let uniqueArr = data.filter((item, index) => {
        return (
          data.findIndex(
            obj =>
              obj.forDate === item.forDate &&
              obj.code === item.code &&
              obj.ruleName === item.ruleName &&
              obj.orderPrice === item.orderPrice &&
              obj.innerSn === item.innerSn
          ) === index
        )
      })
      const sums = []
      const that = this
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '汇总'
          return
        }
        if (index === 6 || index == 7) {
          sums[index] = '0'
          data.map(item => {
            sums[index] = that.$amount.add(sums[index], item[column.property] || '0')
          })
          sums[index] = that.$amount.add(sums[index], '0', 1) || '0' // 留一位
        }
      })
      sums[3] = uniqueArr.reduce((c, r) => this.$amount.add(c, r['orderPrice']), 0)
      sums[3] = that.$amount.add(sums[3], '0', 1) // 留一位
      return sums
    }
  },
  updated() {
    this.$nextTick(() => {
      this.$refs.table.doLayout()
    })
  }
}
</script>

<style lang="scss">
.team-sales {
  border-radius: 10px;
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog__body {
    padding: 0;
  }
}
</style>

<style lang="scss" scoped>
.team-title {
  line-height: 50px;
  margin: 0 10px;
  > div {
    i {
      width: 6px;
      height: 24px;
      background: #0b83f3;
      margin-right: 6px;
      border-radius: 2px;
    }
    span {
      font-size: 20px;
    }
  }
  > i {
    font-size: 20px;
    font-weight: bold;
    color: #666;
    cursor: pointer;
  }
}
.team-body {
  background: #fafafa;
  border-radius: 0 0 10px 10px;
  padding-bottom: 15px;
  .team-from {
    padding: 15px 10px 0;
  }
  .team-table {
    padding: 0 10px;
  }
}
</style>
